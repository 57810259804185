import React, {useEffect, useRef, useState} from 'react';
import {
    Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

import BillingService from "../../../services/billing.service";
import Utils from "../../Utils";
import Select from "react-select";
import Toastr from "../../notifications/Toastr";
import UserService from "../../../services/user.service";

import moment from "moment";
import 'moment/locale/de';
import DayCell from "./DayCell";
import Holidays from "date-holidays";
import TokenService from "../../../services/token.service";


const WorkDayManagement = (props) => {


    const getLeftDays = () => {
        const currentYear = new Date().getFullYear();
        const user = TokenService.getUser().user;
        let daysLeft = 0;

        for (let year = 2022; year <= currentYear; year++) {
            let vacationDayCount;

            if (year >= 2024) {
                vacationDayCount = user.userId === 1 ? 27 : 24;
            } else {
                vacationDayCount = user.vacation_day_count;
            }

            daysLeft += vacationDayCount;
        }

        for (const x of daysOff) {
            if (x.user_id === user.userId) {
                switch (x.selected_type) {
                    case "vacation":
                        daysLeft--;
                        break;
                    case "vacation-half-e":
                    case "vacation-half-s":
                        daysLeft -= 0.5;
                        break;
                }
            }
        }

        return daysLeft;
    }

    const [actionMenuOpen, toggleActionMenu] = useState(false);

    const possibleMonths = [
        { value: 1, label: 'Januar'},
        { value: 2, label: 'Februar'},
        { value: 3, label: 'März'},
        { value: 4, label: 'April'},
        { value: 5, label: 'Mai'},
        { value: 6, label: 'Juni'},
        { value: 7, label: 'Juli'},
        { value: 8, label: 'August'},
        { value: 9, label: 'September'},
        { value: 10, label: 'Oktober'},
        { value: 11, label: 'November'},
        { value: 12, label: 'Dezember'},
    ]

    const possibleYears = [
        { value: 2021, label: '2021'},
        { value: 2022, label: '2022'},
        { value: 2023, label: '2023'},
        { value: 2024, label: '2024'},

    ]



    const [employees, setEmployees] = useState([{}]);
    const [daysOff, setDaysOff] = useState([{}]);
    const [selectedDays, setSelectedDays] = useState([{}]);
    const [selectedCompany, changeCompany] = useState("");
    const [selectedMonth, setMonth] = useState(possibleMonths.find(m => m.value === new Date().getMonth()+1));
    const [selectedYear, setYear] = useState(possibleYears.find(y => y.value === new Date().getFullYear()));

    const exportWU = async () =>{

        let exportText = ``;
        let currentDate = ""
        for(let wuE of employees){
            if(currentDate != wuE.date_selected){
                exportText += `\n`
                currentDate = wuE.date_selected;
                exportText += `${Utils.formatDateNoHour(wuE.date_selected)}\n`
            }
            exportText += `${wuE.wu < 10 ? " "+wuE.wu : wuE.wu}AE - ${wuE.description}\n`
        }


        navigator.clipboard.writeText(exportText).then(Toastr.success("In die Zwischenablage exportiert."))

    }
    const possibleDayOptions = [
        { value: '', label: 'Keine'},
        { value: 'sickness', label: 'Krankheit'},
        { value: 'homeoffice', label: 'Home Office'},
        { value: 'vacation', label: 'Urlaub'},
        { value: 'vacation-half-s', label: 'Urlaub (Vormittag)'},
        { value: 'vacation-half-e', label: 'Urlaub (Nachmittag)'},
    ]


    const getDatesInRange= (startDate, endDate) => {
        const date = new Date (new Date(startDate.getTime()).toDateString());

        const hd = new Holidays()
        hd.init('DE', 'NI')

        const dates = [];

        while (date <= endDate) {
            let nDate = new Date(date);
            nDate.setTime(date.getTime() + 14 * 60 * 60 * 1000);
            nDate.holidayInfo = hd.isHoliday(nDate);

            if(nDate.holidayInfo && nDate.holidayInfo[0].type == "observance"){
                nDate.holidayInfo = false;
            }

            dates.push(nDate);

            date.setDate(date.getDate() + 1);


        }

        return dates;
    }


    let month = -1;
    let monthSpan = 1;
    let year = -1;
    let yearSpan = 1;
    const refreshTable = () =>{
        UserService.getUserSummary().then((response) => {


            setEmployees(response.data.filter(x => x.email.endsWith("cva.de")))

            let m = moment(selectedYear.value+ "/" + selectedMonth.value, 'YYYY/M');
            const c = new Date();

            let minus = 11;
            if(selectedYear.value === c.getFullYear() && selectedMonth.value === c.getMonth()+1){
                m =  new moment();

                minus = c.getDate()-1
            }
            console.log(c.getMonth())
            console.log(selectedMonth.value)
            console.log("m")
            console.log(m)
            setSelectedDays(getDatesInRange(
                m.subtract(minus, "d").toDate(),
                m.add(45, "d").toDate()))

        })

        UserService.getDaysOff().then(response => {
            for(let e of response.data)
                e.day = new Date(e.day);
            setDaysOff( response.data)
            console.log(daysOff)
        })
    }

    useEffect(() => {
        refreshTable()
    }, [])
    useEffect(() => {
        refreshTable()
    }, [selectedCompany, selectedMonth, selectedYear])
    return (
        <div>
            <Row className={""}>
                <Col xs="5">
                    <div className={"bg-darker pt-2"}>
                    <p className="ps-3 pb-1">Verbleibende Urlaubstage: {getLeftDays()}/{TokenService.getUser().user.vacation_day_count}</p>
                    </div>
                </Col>
                <Col xs="3">
                    <Select
                        name="groups"
                        options={possibleMonths}
                        defaultValue={possibleMonths.find(m => m.value === new Date().getMonth()+1)}
                        className="basic-multi-select"
                        onChange={(el) => {
                            setMonth(el)
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        placeholder={"Nach Monat filtern"}
                        classNamePrefix="select"
                    />
                </Col>

                <Col xs="3">
                    <Select
                        name="groups"
                        options={possibleYears}
                        defaultValue={possibleYears.find(y => y.value === new Date().getFullYear())}
                        className="basic-multi-select"
                        onChange={(el) => {
                            setYear(el)
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        placeholder={"Nach Jahr filtern"}
                        classNamePrefix="select"
                    />
                </Col>
                <Col xs="1" className="float-end">

                        <Button id="action" color="success" onClick={refreshTable}>{"-->"}</Button>

                </Col>


            </Row>
            <Row className={"overflow-auto"}>
                <Col xs={12}>
                    <Table className="table-dark table-striped  table-borderless table- mt-5 ">
                        <thead className="top-0 bg-darker">
                        <tr>
                            <th className=" top-0 bg-darker" style={{minWidth : "12em"}}>Mitarbeiter</th>
                            {
                                selectedDays.map((day, i) =>
                                    <th className=" top-0 bg-darker text-center ">{new moment(day).locale('de').format('dd')}</th>

                                )
                            }


                        </tr>
                        </thead>
                        <tbody>
                        {
                            employees.map((employee, i) =>
                                <tr>
                                    <th scope="row" className={" emp-cell"}>{employee.firstName} {employee.lastName}</th>
                                    {
                                        selectedDays.map((day, i) =>


                                            <DayCell openWindow={props.openWindow} refreshTable={refreshTable} day={day} possibleDayOptions={possibleDayOptions} empID={employee.id} extraInfo={daysOff.find(d => d.user_id === employee.id && (d.day && typeof d.day.getFullYear === 'function'&& typeof day.getFullYear === 'function') && d.day.getFullYear() === day.getFullYear()
                                                && d.day.getMonth() === day.getMonth()
                                                && d.day.getDate() === day.getDate()
                                            )}/>
                                        )
                                    }



                                </tr>
                            )
                        }
                        <tr>
                            <td className={"font-weight-bolder text-center"}>Monat</td>
                            {
                                selectedDays.map((day, i) =>{
                                    if(!day.getMonth)
                                        return;

                                    monthSpan++;
                                    if(month != day.getMonth()){
                                        monthSpan = 1;
                                        month = day.getMonth()
                                    }

                                    if(moment(day).add("1", "d").month() != month || i + 1 === selectedDays.length){

                                        return <th colSpan={monthSpan} className={"bg-darker text-center month-cell"}>{moment(day).format('MMMM')}</th>
                                    }

                                    }
                                )
                            }
                        </tr>
                        <tr>
                            <td className={"font-weight-bolder text-center"}>Jahr</td>
                            {
                                selectedDays.map((day, i) =>{
                                    if(!day.getFullYear)
                                        return;

                                    yearSpan++;
                                    if(year != day.getFullYear()){
                                        yearSpan = 1;
                                        year = day.getFullYear()
                                    }


                                    if(moment(day).add("1", "d").year() != year || i + 1 === selectedDays.length){

                                        return <th colSpan={yearSpan} className={"bg-darker text-center month-cell"}>{moment(day).format('YYYY')}</th>
                                    }

                                    }
                                )
                            }
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
};

export default WorkDayManagement;